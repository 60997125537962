@import '~@Styles/globals.scss';

.c_modal__layer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.158);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.c_modal {
    width: 98%;
    background: white;
    // border-radius: 15px;
}

.c_modal__header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.c_modal__close{
    height: 25px;
    cursor: pointer;
}

.c_modal__body {
    height: 70%;
    padding: 0 16px;
}

.c_modal__footer {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: flex-end;
    & button {
        min-width: 3rem;
    }
    & button:first-of-type{
        margin-right: 16px;
    }
}

@media screen and (min-width: $breakpoints-sm){}
@media screen and (min-width: $breakpoints-md){

    .c_modal {
        width: 50%;
        max-width: 750px;
    }

}
@media screen and (min-width: $breakpoints-lg){
    .c_modal__header {
        padding: 24px;
    }

    .c_modal__footer {
        padding: 24px;
    }
}
@media screen and (min-width: $breakpoints-xl){}