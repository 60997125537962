@import '~@Styles/globals.scss';

.c_navegation{
    display: flex;
    align-items: center;
    @include fadein(1s);
}

.c_navegation__icon{
    height: 28px;
    margin-right: 16px;
    cursor: pointer;
}

.c_navegation__title{
    color: $color-purple;
    text-transform: uppercase;
    font-size: 32px;
    &.work{
        font-size: 24px;
    }
}

@media screen and (min-width: $breakpoints-sm){}

@media screen and (min-width: $breakpoints-md){
    .c_navegation__title{
        font-size: 42px;
        &.work{
            font-size: 32px;
        }
    }
}

@media screen and (min-width: $breakpoints-lg){
    .c_navegation__title{
        font-size: 42px;
        &.work{
            font-size: 32px;
        }
    }
}

@media screen and (min-width: $breakpoints-xl){}