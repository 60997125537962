@import '~@Styles/breakpoints.scss';

.e-icon-18x {
    height: 180px;
    width: 180px;
}

.e-icon-17x {
    height: 170px;
    width: 170px;
}

.e-icon-16x {
    height: 160px;
    width: 160px;
}

.e-icon-15x {
    height: 150px;
    width: 150px;
}

.e-icon-14x {
    height: 140px;
    width: 140px;
}

.e-icon-13x {
    height: 130px;
    width: 130px;
}

.e-icon-12x {
    height: 120px;
    width: 120px;
}

.e-icon-11x {
    height: 110px;
    width: 110px;
}

.e-icon-10x {
    height: 100px;
    width: 100px;
}

.e-icon-9x {
    height: 90px;
    width: 90px;
}

.e-icon-8x {
    height: 80px;
    width: 80px;
}

.e-icon-7x {
    height: 72px;
    width: 72px;
}

.e-icon-6x {
    height: 64px;
    width: 64px;
}

.e-icon-5x {
    height: 52px;
    width: 52px;
}

.e-icon-4x {
    height: 48px;
    width: 48px;
}

.e-icon-3x {
    height: 36px;
    width: 36px;
}

.e-icon-2x {
    height: 24px;
    width: 24px;
}

.e-icon-2x {
    height: 16px;
    width: 16px;
}

.e-icon-2x {
    height: 8px;
    width: 8px;
}

.e-icon-white{
    fill: white;
}

.e-icon-h-18x {
    height: 180px;
}

.e-icon-h-17x {
    height: 170px;
}

.e-icon-h-16x {
    height: 160px;
}

.e-icon-h-15x {
    height: 150px;
}

.e-icon-h-14x {
    height: 140px;
}

.e-icon-h-13x {
    height: 130px;
}

.e-icon-h-12x {
    height: 120px;
}

.e-icon-h-11x {
    height: 110px;
}

.e-icon-h-10x {
    height: 100px;
}

.e-icon-h-9x {
    height: 90px;
}

.e-icon-h-8x {
    height: 80px;
}

.e-icon-h-7x {
    height: 72px;
}

.e-icon-h-6x {
    height: 64px;
}

.e-icon-h-5x {
    height: 52px;
}

.e-icon-h-4x {
    height: 48px;
}

.e-icon-h-3x {
    height: 36px;
}

.e-icon-h-2x {
    height: 24px;
}

@media screen and (min-width: $breakpoints-sm){
    .e-icon-15x\:sm {
        height: 150px;
        width: 150px;
    }           
    
    .e-icon-14x\:sm {
        height: 140px;
        width: 140px;
    }           
    
    .e-icon-13x\:sm {
        height: 130px;
        width: 130px;
    }           
    
    .e-icon-12x\:sm {
        height: 120px;
        width: 120px;
    }           
    
    .e-icon-11x\:sm {
        height: 110px;
        width: 110px;
    }           
    
    .e-icon-10x\:sm {
        height: 100px;
        width: 100px;
    }           
    
    .e-icon-9x\:sm {
        height: 90px;
        width: 90px;
    }           
    
    .e-icon-8x\:sm {
        height: 80px;
        width: 80px;
    }           
    
    .e-icon-7x\:sm {
        height: 72px;
        width: 72px;
    }           
    
    .e-icon-6x\:sm {
        height: 64px;
        width: 64px;
    }           
    
    .e-icon-5x\:sm {
        height: 52px;
        width: 52px;
    }           
    
    .e-icon-4x\:sm {
        height: 48px;
        width: 48px;
    }           
    
    .e-icon-3x\:sm {
        height: 36px;
        width: 36px;
    }           
    
    .e-icon-2x\:sm {
        height: 24px;
        width: 24px;
    }           
    
    .e-icon-2x\:sm {
        height: 16px;
        width: 16px;
    }           
    
    .e-icon-2x\:sm {
        height: 8px;
        width: 8px;
    }
    
    .e-icon-h-18x\:sm {
        height: 180px;
    }
    
    .e-icon-h-17x\:sm {
        height: 170px;
    }
    
    .e-icon-h-16x\:sm {
        height: 160px;
    }
    
    .e-icon-h-15x\:sm {
        height: 150px;
    }           
    
    .e-icon-h-14x\:sm {
        height: 140px;
    }           
    
    .e-icon-h-13x\:sm {
        height: 130px;
    }           
    
    .e-icon-h-12x\:sm {
        height: 120px;
    }           
    
    .e-icon-h-11x\:sm {
        height: 110px;
    }           
    
    .e-icon-h-10x\:sm {
        height: 100px;
    }           
    
    .e-icon-h-9x\:sm {
        height: 90px;
    }           
    
    .e-icon-h-8x\:sm {
        height: 80px;
    }           
    
    .e-icon-h-7x\:sm {
        height: 72px;
    }           
    
    .e-icon-h-6x\:sm {
        height: 64px;
    }           
    
    .e-icon-h-5x\:sm {
        height: 52px;
    }           
    
    .e-icon-h-4x\:sm {
        height: 48px;
    }           
    
    .e-icon-h-3x\:sm {
        height: 36px;
    }           
    
    .e-icon-h-2x\:sm {
        height: 24px;
    }           
    
    .e-icon-h-2x\:sm {
        height: 16px;
    }           
    
    .e-icon-h-2x\:sm {
        height: 8px;
    }  
}


@media screen and (min-width: $breakpoints-md){

    .e-icon-18x\:md {
        height: 180px;
        width: 180px;
    }
    
    .e-icon-17x\:md {
        height: 170px;
        width: 170px;
    }
    
    .e-icon-16x\:md {
        height: 160px;
        width: 160px;
    }

    .e-icon-15x\:md {
        height: 150px;
        width: 150px;
    }           
    
    .e-icon-14x\:md {
        height: 140px;
        width: 140px;
    }           
    
    .e-icon-13x\:md {
        height: 130px;
        width: 130px;
    }           
    
    .e-icon-12x\:md {
        height: 120px;
        width: 120px;
    }           
    
    .e-icon-11x\:md {
        height: 110px;
        width: 110px;
    }           
    
    .e-icon-10x\:md {
        height: 100px;
        width: 100px;
    }           
    
    .e-icon-9x\:md {
        height: 90px;
        width: 90px;
    }           
    
    .e-icon-8x\:md {
        height: 80px;
        width: 80px;
    }           
    
    .e-icon-7x\:md {
        height: 72px;
        width: 72px;
    }           
    
    .e-icon-6x\:md {
        height: 64px;
        width: 64px;
    }           
    
    .e-icon-5x\:md {
        height: 52px;
        width: 52px;
    }           
    
    .e-icon-4x\:md {
        height: 48px;
        width: 48px;
    }           
    
    .e-icon-3x\:md {
        height: 36px;
        width: 36px;
    }           
    
    .e-icon-2x\:md {
        height: 24px;
        width: 24px;
    }           
    
    .e-icon-2x\:md {
        height: 16px;
        width: 16px;
    }           
    
    .e-icon-2x\:md {
        height: 8px;
        width: 8px;
    }
    
    .e-icon-h-18x\:md {
        height: 180px;
    }
    
    .e-icon-h-17x\:md {
        height: 170px;
    }
    
    .e-icon-h-16x\:md {
        height: 160px;
    }
    
    .e-icon-h-15x\:md {
        height: 150px;
    }           
    
    .e-icon-h-14x\:md {
        height: 140px;
    }           
    
    .e-icon-h-13x\:md {
        height: 130px;
    }           
    
    .e-icon-h-12x\:md {
        height: 120px;
    }           
    
    .e-icon-h-11x\:md {
        height: 110px;
    }           
    
    .e-icon-h-10x\:md {
        height: 100px;
    }           
    
    .e-icon-h-9x\:md {
        height: 90px;
    }           
    
    .e-icon-h-8x\:md {
        height: 80px;
    }           
    
    .e-icon-h-7x\:md {
        height: 72px;
    }           
    
    .e-icon-h-6x\:md {
        height: 64px;
    }           
    
    .e-icon-h-5x\:md {
        height: 52px;
    }           
    
    .e-icon-h-4x\:md {
        height: 48px;
    }           
    
    .e-icon-h-3x\:md {
        height: 36px;
    }           
    
    .e-icon-h-2x\:md {
        height: 24px;
    }           
    
    .e-icon-h-2x\:md {
        height: 16px;
    }           
    
    .e-icon-h-2x\:md {
        height: 8px;
    }
}



@media screen and (min-width: $breakpoints-lg){
    .e-icon-15x\:lg {
        height: 150px;
        width: 150px;
    }           
    
    .e-icon-14x\:lg {
        height: 140px;
        width: 140px;
    }           
    
    .e-icon-13x\:lg {
        height: 130px;
        width: 130px;
    }           
    
    .e-icon-12x\:lg {
        height: 120px;
        width: 120px;
    }           
    
    .e-icon-11x\:lg {
        height: 110px;
        width: 110px;
    }           
    
    .e-icon-10x\:lg {
        height: 100px;
        width: 100px;
    }           
    
    .e-icon-9x\:lg {
        height: 90px;
        width: 90px;
    }           
    
    .e-icon-8x\:lg {
        height: 80px;
        width: 80px;
    }           
    
    .e-icon-7x\:lg {
        height: 72px;
        width: 72px;
    }           
    
    .e-icon-6x\:lg {
        height: 64px;
        width: 64px;
    }           
    
    .e-icon-5x\:lg {
        height: 52px;
        width: 52px;
    }           
    
    .e-icon-4x\:lg {
        height: 48px;
        width: 48px;
    }           
    
    .e-icon-3x\:lg {
        height: 36px;
        width: 36px;
    }           
    
    .e-icon-2x\:lg {
        height: 24px;
        width: 24px;
    }           
    
    .e-icon-2x\:lg {
        height: 16px;
        width: 16px;
    }           
    
    .e-icon-2x\:lg {
        height: 8px;
        width: 8px;
    }

    .e-icon-h-15x\:lg {
        height: 150px;
    }           
    
    .e-icon-h-14x\:lg {
        height: 140px;
    }           
    
    .e-icon-h-13x\:lg {
        height: 130px;
    }           
    
    .e-icon-h-12x\:lg {
        height: 120px;
    }           
    
    .e-icon-h-11x\:lg {
        height: 110px;
    }           
    
    .e-icon-h-10x\:lg {
        height: 100px;
    }           
    
    .e-icon-h-9x\:lg {
        height: 90px;
    }           
    
    .e-icon-h-8x\:lg {
        height: 80px;
    }           
    
    .e-icon-h-7x\:lg {
        height: 72px;
    }           
    
    .e-icon-h-6x\:lg {
        height: 64px;
    }           
    
    .e-icon-h-5x\:lg {
        height: 52px;
    }           
    
    .e-icon-h-4x\:lg {
        height: 48px;
    }           
    
    .e-icon-h-3x\:lg {
        height: 36px;
    }           
    
    .e-icon-h-2x\:lg {
        height: 24px;
    }           
    
    .e-icon-h-2x\:lg {
        height: 16px;
    }           
    
    .e-icon-h-2x\:lg {
        height: 8px;
    }
}