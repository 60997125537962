.c_accordion__list{
    list-style: none;
    padding:0;
    border-top: 2px solid black;
    padding-top: 8px;
}

.c_accordion__list_item{
    text-transform: uppercase;
    padding:8px;
    cursor: pointer;
}