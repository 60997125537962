@import '~@Styles/globals.scss';

.c_working_list__load_more{
    margin: 32px auto !important;
}

.c_working_list__total_result{
    padding: 24px 0;
}

@media screen and (min-width: $breakpoints-sm){}

@media screen and (min-width: $breakpoints-md){
    .c_working_list__load_more{
        margin: 48px auto !important;
    }

    .c_working_list__total_result{
        text-align: left;
    }
}
