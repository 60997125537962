@import '~@Styles/globals.scss';

.c_resource_collection{
    @include fadein(1s);
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    & details {
        margin-top: 32px;
       width: 100%; 
    }
}

.c_resource_collection_list{
    list-style: none;
    padding: 0;
    & li {
        & input {
            margin: 12px;
            margin-left:0;
            padding: 0;
        }
    }
}

.c_resource_collection_list_item{
    & a {
        color: black;
        cursor: pointer;
    }
}

.c_resource_collection_summary{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

@media screen and (min-width: $breakpoints-sm){}
@media screen and (min-width: $breakpoints-md){
    .c_resource_collection{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    
        & details {
            margin-top: none;
           width: 40%; 
        }
    }
}
@media screen and (min-width: $breakpoints-lg){}
@media screen and (min-width: $breakpoints-xl){}