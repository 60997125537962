@import '~@Styles/globals.scss';

.l_main {

}

.l_main__header {
    max-width: 1600px;
    margin: 0 auto;
    padding: 16px calc(32px);
}

.l_main__body {
    max-width: 1600px;
    margin: 0 auto;
    padding: 16px 32px;
    padding-bottom: 52px;
    min-height: calc(100vh - (104px + 140px + (16px * 2)));
}

.l_main__footer {
    max-width: 100%;
}

@media screen and (min-width: $breakpoints-md){
    .l_main__header {
        padding: 16px calc(32px + 8px);
    }
      
}