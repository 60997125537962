@import '~@Styles/globals.scss';

.c_loader {
    &.white{
        @include loader10($size: 10px, $gap: 8px, $duration: 1s, $align: middle, $color: white);
    }
    &.purple{
        @include loader10($size: 10px, $gap: 8px, $duration: 1s, $align: middle, $color: $color-purple);
    }
}
