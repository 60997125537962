@import '~@Styles/globals.scss';

.c_header {
    display: flex;
    justify-content: space-between;
    background-color: black;
    align-items: baseline;
    a {
        font-size: 0;
    }
}

.c_header__logo {
    height: 60px;
    font-size: 0;
    fill: white;
}

.c_header__nav {
    display: none;
    position: fixed;
    z-index: 1000;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.85);
    flex-direction: column;
    padding: 100px 16px;
    
    &.show{
        display: flex;
    }
}

.c_header__nav_item{
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    padding: 12px 0;
    text-decoration: none;
    font-size: 32px;
    line-height: 1.2;
    cursor: pointer;

    :hover {
        text-decoration: underline;
    }
}

.c_header__nav_button_close {
    position: absolute;
    top: 0;
    left: 0;
    width: 42px;
    height: 42px;
    background:transparent;
    border: none;
    padding: 18px 0 0 18px;
    & svg {
        fill: white;
    }
}

.c_header__nav_menu{
    font-size: 0;
    svg {
        fill: white;  
    }
}

@media screen and (min-width: $breakpoints-md){

    .c_header{
        position: relative;
    }

    .c_header__logo{
        left: 10px;
        top: 100%;
        height: 60px;
    }

    .c_header__logo__Mali {
        height: 70px;
        width: auto;
    }

    .c_header__nav {
        position: inherit;
        background: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 16px 40px;
        max-width: 1600px;
        margin: 0 auto;
    }

    .c_header__nav_item{
        color: black;
        width: 14%;
        font-size: 20px;
        text-align: center;
    }

    .c_header__nav_button_close{
        display: none;
    }

    .c_header__nav_menu{
        display: none;
    }
}

@media screen and (min-width: $breakpoints-lg){
    .c_header__nav_item{
        font-size: 24px;
    }
}

@media screen and (min-width: $breakpoints-xl){
    .c_header__nav_item{
        font-size: 28px;
    }
}