@import '~@Styles/globals.scss';

.c_search {
    background: white;
    display: flex;
    flex-direction:column;
    align-items: center;
}

.c_search__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    max-width: 1600px;
}

.c_search__head_logo {
    height: 50px;
}

.c_search__head_close{
    height: 25px;
    cursor: pointer;
}

.c_search__content {
    @include fadein(1s);
    //padding: 16px 32px;
    width: 100%;
    max-width: 1600px;
}

.c_search__title{
    font-size: 52px;
    padding-bottom: 32px;
    color: $color-purple;
    outline: none;
}

.c_search__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 0;
}

.c_search__input_title {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    padding: 8px 0;
    text-align: center;
    color: black;
}


.c_search__form_input_group{
    text-align: center;
    width: 100%;
    margin: 8px 0;
}

.c_search__form_input_group_label{
    padding-bottom: 10px;
}

.c_search__form_input_group_select {
    text-align-last:center;
    border: 2px solid $color-purple;
    background: white;
    color: $color-purple;
    padding: 8px;
    width: 100%;
    appearance: none;
    text-transform: uppercase;
    font-weight: bold;

    & * {
        @extend .e-text-regular;
        font-size: 14px;
    }

    &__control{
        border:none !important;
        min-height: 0 !important;
        outline: none;
        &:hover{
            border:none !important;
        }
    }
    &__indicators{
        // display:none !important;
    }
    &__value-container{
        padding: none !important;
    }
    &__placeholder{
        color:$color-purple !important;
    }
    &__value-container{
        width: 120% !important;
    }
}

.c_search__button_wrapper{
    text-align: right;
    width: 100%;
}

.c_search__button{
    margin-top: 32px;
    width: 100%;
    margin: 0 auto;
}

.c_search__form_input_group{
    flex-direction: row;
}

.c_search__result{
    text-align: center;
    width: 100%;
    max-width: 1600px;
}

.c_search__button_load_more{
    width: 100%;
    margin-bottom: 32px !important;
}

@media screen and (min-width: $breakpoints-sm){
}

@media screen and (min-width: $breakpoints-md){
    .c_search__content{
        position: relative;
        margin-bottom: 46px;
    }

    .c_search__title{
        font-size:46px;
    }

    .c_search__form{
        flex-direction: row;
        justify-content: space-between;
    }

    .c_search__form_input_group{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width:30%;
    }

    .c_search__form_input_group_label{
        width: 45%;
        padding: 0;
    }

    .c_search__form_input_group_select{
        width: 55%;
    }

    .c_search__button{
        width: 150px;
    }
    
    .c_search__button_load_more{
        width: 30%;
    }
}

@media screen and (min-width: $breakpoints-lg){
    .c_search__button_load_more{
        width: 15%;
    }
}

@media screen and (min-width: $breakpoints-xl){}