@import '~@Styles/globals.scss';

.c_work {
    border: 1px solid black;
    padding: 16px;
    text-align: left;
    @include fadein();
}

.c_work__image {
    width: 100%;
    // min-height: 40px;R
}

.c_work__title {

}

.c_work__detail{
    border-top: 2px solid black;
    padding-top: 8px;
    margin: 8px 0;
    //justify-content: space-between;
}

.c_work__description{
    text-align: right;
}

.c_work__button {
    margin-top: 16px !important;
    width: 100%;
}


@media screen and (min-width: $breakpoints-sm){}

@media screen and (min-width: $breakpoints-md){}

@media screen and (min-width: $breakpoints-lg){}

@media screen and (min-width: $breakpoints-xl){}