@import '~@Styles/globals.scss';

.c_masonry_grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
}
.c_masonry_grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}

.c_masonry_grid_column > div {
    margin-bottom: 30px;
}