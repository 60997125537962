@import '~@Styles/globals.scss';

.c_summary__detail{
    
}

.c_summary__title::-webkit-details-marker {
    display: none;
}

.c_summary__title{
    outline: none;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.c_summary__title_icon {
    & svg{
        fill: black;
        width:20px;
        height: 20px;
        transition: all 0.2s;
    }
}

details[open] span {
    & svg {transform: rotate(90deg)}
}

@media screen and (min-width: $breakpoints-sm){}
@media screen and (min-width: $breakpoints-md){}
@media screen and (min-width: $breakpoints-lg){}
@media screen and (min-width: $breakpoints-xl){}