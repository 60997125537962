

//$color-pink: #FF00F3;
$color-pink: black;
//$color-pink-opacity: #0000005d;
//$color-pink-opacity: #3c00717c;
//$color-pink-opacity: rgba(255, 0, 243, 0.3);
$color-pink-opacity: black;
//$color-purple: #3D0071;
$color-purple: black;
$color-purple-acid: black;
//$color-purple-acid: #6236FF;
$color-gray-dark: #262626;

$color-blue-10: #e1ddee; 
$color-blue-20: #c3bbde; 
$color-blue-30: #a59acd;
$color-blue-40: #8778bd;
$color-blue-50: #6A57AD;
$color-blue-60: #54458a;
$color-blue-70: #3f3467;

$color-gray-5: #eeeff3;
$color-gray-10: #e5e5e5;
$color-gray-15: #cccccc;
$color-gray-20: #b2b2b2;
$color-gray-30: #999999;
$color-gray-40: #7f7f7f;
$color-gray-50: #666666;
$color-gray-60: #4c4c4c;
$color-gray-70: #323232;
$color-gray-80: #191919;

$color-primary: white;
$color-secondary: #136a8a;
