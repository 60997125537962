@import '~@Styles/globals.scss';

.p_recursos__pharagraph{
    padding: 42px 0;
    padding-left: 42px;
    @include fadein(1s);
    p , span {
        font-family: "Raleway_Regular" !important; 
    }
}

.p_recursos__coleccion_grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px;
    justify-content: center;

    & > div {
        flex: 100%;
        max-width: 100%;
        margin:  1.6% 0;
        height: 174px;
    }
}

@media screen and (min-width: $breakpoints-sm){
    .p_recursos__coleccion_grid {
        // justify-content: flex-start;
        & > div {
            max-width: 326px;
            margin:  8px;
            height: 174px;
        }
    }
}

@media screen and (min-width: $breakpoints-md){
    .p_recursos__coleccion_grid {
        & > div {
            max-width: 300px;
        }
    }
}

@media screen and (min-width: $breakpoints-lg){
    .p_recursos__coleccion_grid {
        // justify-content: flex-start;
        & > div {
            max-width: 320px;
            height: 220px;
        }
    }
}

@media screen and (min-width: $breakpoints-xl){}