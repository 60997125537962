@import '~@Styles/globals.scss';

.p_obra__content_info * {
    padding: 8px 0;
}

.p_obra__content_info_buttons{
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    & button {
        margin: 8px 0;
    }
}

.p_obra__content_info_label{
    text-transform: uppercase;
    color: $color-purple;
}

.p_obra__content_image {
    width: 100%;
    & div {
        width: 100%;
        height: auto;
    }
}

.p_obra__content_image_img {
    width: 100%;
    object-fit: contain;
    margin: 0 auto;
    max-height: 60vh;
    &__Zoom {
        width: 100%;
        height:  100%;
        object-fit: contain;
    }
}

.p_obra__content_info_tags{
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    & a{
        border: 2px solid $color-purple-acid;
        color: $color-purple-acid;
        padding: 6px 8px;
        margin-right: 24px;
        margin-top: 16px;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
    }
}

.p_obra__content{
    margin-top: 42px;
    @include fadein(1s);
}

.p_obra__content_info{
    margin-top: 32px;
}

.p_obra__modal_icon{
    fill:$color-purple;
    height: 60px;
}

.p_obra__modal_wrapper{
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
}

.p_obra__content__Credits_wrapper {
    display: flex;
    gap: 16px;
}

.p_obra__content__Credits_wrapper__Link {
    
}

@media screen and (min-width: $breakpoints-sm){
    .p_obra__content_image{
        text-align: center;
        & img{width: 80%;}
    }
}

@media screen and (min-width: $breakpoints-md){
    .p_obra__content{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
    }

    .p_obra__content_info{
        margin-top: 0px;
        margin-left: calc(28px + 16px);
    }

    .p_obra__content_image{
        width: 50%;
        margin: 16px;
    }

    .p_obra__content_image_img{
        width: 100%;
        max-height: 500px;
        object-fit: contain;
        margin: 0 auto;
        &__Zoom {
            width: 100%;
            height:  100%;
            object-fit: contain;
        }
    }

    .p_obra__content_info {
        width: 50%;
    }

    .p_obra__content_info_buttons{
        display: flex;
        // flex-direction: row-reverse;
        justify-content: space-between;
        & button {
            width: 30%;
        }
    }
}

@media screen and (min-width: $breakpoints-lg){
    .p_obra__content_image{
        width: 50%;
    }
}

@media screen and (min-width: $breakpoints-xl){
    .p_obra__content_image{
        width: 50%;
    }
}