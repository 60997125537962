@import '~@Styles/globals.scss';

.p_coleccion{
    margin-top:32px;
    display: flex;
    justify-content:space-between;
    flex-direction: column;
    padding-left: 42px;
    @include fadein(1s);
    & > details{
        margin: 16px 0;
    }
}

@media screen and (min-width: $breakpoints-sm){
}

@media screen and (min-width: $breakpoints-md){
    .p_coleccion{
        flex-wrap: wrap;
        flex-direction: row;
        & > details{
            width:40%;
        }
    }
}

@media screen and (min-width: $breakpoints-lg){}

@media screen and (min-width: $breakpoints-xl){}