@media screen and (max-width: 767px){
  .e-show-tablet,
  .e-hide-mobile{
    display: none;
  }
}

@media screen and (min-width: $breakpoints-sm){
  .e-show-mobile{
    display: none;
  }
}

@media screen and (min-width: $breakpoints-sm) and (max-width: 1279px){
  .e-hide-tablet{
    display: none;
  }
}

@media screen and (max-width: 1279px){
  .e-show-desktop{
    display: none;
  }
}

@media screen and (min-width: $breakpoints-lg){
  .e-show-tablet,
  .e-hide-desktop{
    display: none;
  }
}
