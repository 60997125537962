@import '~@Styles/breakpoints.scss';

@font-face {
    font-family: "Poppins_Light";
    src: url(../fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins_Regular";
    src: url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');

}

@font-face {
  font-family: "Poppins_Medium";
  src: url(../fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: "Poppins_Bold";
    src: url(../fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: "Raleway_Light";
  src: url(../fonts/Raleway/Raleway-Light.ttf) format('truetype');
}

@font-face {
  font-family: "Raleway_Regular";
  src: url(../fonts/Raleway/Raleway-Regular.ttf) format('truetype');

}

@font-face {
font-family: "Raleway_Medium";
src: url(../fonts/Raleway/Raleway-Medium.ttf) format('truetype');
}

@font-face {
  font-family: "Raleway_Bold";
  src: url(../fonts/Raleway/Raleway-Bold.ttf) format('truetype');
}



.e-text-regular{ font-family: "Raleway_Regular"; font-weight: normal; margin: 0; }
.e-text-medium{ font-family: "Raleway_Medium"; font-weight: bold; margin: 0; }
.e-text-bold{ font-family: "Raleway_Bold"; font-weight: bold; margin: 0; }

.e-title-regular{ font-family: "Poppins_Regular"; font-weight: normal; margin: 0; }
.e-title-medium{ font-family: "Poppins_Medium"; font-weight: bold; margin: 0; }
.e-title-bold{ font-family: "Poppins_Bold"; font-weight: bold; margin: 0; }

.e-text-wrap{ word-wrap: break-word; }

.e-h1, .e-h2, .e-h3, .e-h4, .e-h5, .e-h6{
  @extend .e-title-medium;
}
.e-p1, .e-p2, .e-p3, .e-p4, .e-p5, .e-p6{
  @extend .e-text-regular;
  margin: 0;
}

.e-h1{ font-size: 42px; line-height: 46px; }
.e-h2{ font-size: 34px; line-height: 38px; }
.e-h3{ font-size: 28px; line-height: 32px; }
.e-h4{ font-size: 26px; line-height: 30px; }
.e-h5{ font-size: 24px; line-height: 28px; }
.e-h6{ font-size: 22px; line-height: 26px; }

.e-p1{ font-size: 20px; line-height: 24px; }
.e-p2{ font-size: 18px; line-height: 22px; }
.e-p3{ font-size: 16px; line-height: 20px; }
.e-p4{ font-size: 14px; line-height: 18px; }
.e-p5{ font-size: 12px; line-height: 16px; }
.e-p6{ font-size: 10px; line-height: 14px; }

.e-text-center{ text-align: center; }
.e-text-right{ text-align: right; }
.e-text-left{ text-align: left; }

.br-mobile{ display: block; }
.br-desktop{ display: none; }

@media screen and (min-width: $breakpoints-sm){
  .e-h1{ font-size: 46px; line-height: 50px; }
  .e-h2{ font-size: 38px; line-height: 42px; }
  .e-h3{ font-size: 32px; line-height: 36px; }
  .e-h4{ font-size: 28px; line-height: 32px; }
  .e-h5{ font-size: 26px; line-height: 30px; }
  .e-h6{ font-size: 24px; line-height: 28px; }

  .e-p1{ font-size: 22px; line-height: 26px; }
  .e-p2{ font-size: 20px; line-height: 24px; }
  .e-p3{ font-size: 18px; line-height: 22px; }
  .e-p4{ font-size: 16px; line-height: 20px; }
  .e-p5{ font-size: 14px; line-height: 18px; }
  .e-p6{ font-size: 12px; line-height: 16px; }

  .e-h1\:sm{ font-size: 46px; line-height: 54px; }
  .e-h2\:sm{ font-size: 38px; line-height: 46px; }
  .e-h3\:sm{ font-size: 32px; line-height: 40px; }
  .e-h4\:sm{ font-size: 28px; line-height: 32px; }
  .e-h5\:sm{ font-size: 26px; line-height: 30px; }
  .e-h6\:sm{ font-size: 24px; line-height: 28px; }

  .e-p1\:sm{ font-size: 22px; line-height: 26px; }
  .e-p2\:sm{ font-size: 20px; line-height: 24px; }
  .e-p3\:sm{ font-size: 18px; line-height: 22px; }
  .e-p4\:sm{ font-size: 16px; line-height: 20px; }
  .e-p5\:sm{ font-size: 14px; line-height: 18px; }
  .e-p6\:sm{ font-size: 12px; line-height: 16px; }
}

@media screen and (min-width: $breakpoints-md){
  .br-mobile{ display: none; }
  .br-desktop{ display: block; }

  .e-h1,.e-h1\:sm{ font-size: 52px; line-height: 56px; }
  .e-h2,.e-h2\:sm{ font-size: 44px; line-height: 48px; }
  .e-h3,.e-h3\:sm{ font-size: 36px; line-height: 40px; }
  .e-h4,.e-h4\:sm{ font-size: 32px; line-height: 36px; }
  .e-h5,.e-h5\:sm{ font-size: 28px; line-height: 32px; }
  .e-h6,.e-h6\:sm{ font-size: 24px; line-height: 28px; }

  .e-h1\:md{ font-size: 52px; line-height: 56px; }
  .e-h2\:md{ font-size: 44px; line-height: 48px; }
  .e-h3\:md{ font-size: 36px; line-height: 40px; }
  .e-h4\:md{ font-size: 32px; line-height: 36px; }
  .e-h5\:md{ font-size: 28px; line-height: 32px; }
  .e-h6\:md{ font-size: 24px; line-height: 28px; }

  .e-p1\:md{ font-size: 22px; line-height: 26px; }
  .e-p2\:md{ font-size: 20px; line-height: 24px; }
  .e-p3\:md{ font-size: 18px; line-height: 22px; }
  .e-p4\:md{ font-size: 16px; line-height: 20px; }
  .e-p5\:md{ font-size: 14px; line-height: 18px; }
  .e-p6\:md{ font-size: 12px; line-height: 16px; }
}

@media screen and (min-width: $breakpoints-lg){
  .e-p1,.e-p1\:sm,.e-p1\:md{ font-size: 24px; line-height: 28px; }
  .e-p2,.e-p2\:sm,.e-p2\:md{ font-size: 22px; line-height: 26px; }
  .e-p3,.e-p3\:sm,.e-p3\:md{ font-size: 20px; line-height: 24px; }
  .e-p4,.e-p4\:sm,.e-p4\:md{ font-size: 18px; line-height: 22px; }
  .e-p5,.e-p5\:sm,.e-p5\:md{ font-size: 16px; line-height: 20px; }
  .e-p6,.e-p6\:sm,.e-p6\:md{ font-size: 14px; line-height: 18px; }

  .e-h1,.e-h1\:sm,.e-h1\:md{ font-size: 60px; line-height: 64px; }
  .e-h2,.e-h2\:sm,.e-h2\:md{ font-size: 54px; line-height: 58px; }
  .e-h3,.e-h3\:sm,.e-h3\:md{ font-size: 46px; line-height: 50px; }
  .e-h4,.e-h4\:sm,.e-h4\:md{ font-size: 38px; line-height: 42px; }
  .e-h5,.e-h5\:sm,.e-h5\:md{ font-size: 32px; line-height: 36px; }
  .e-h6,.e-h6\:sm,.e-h6\:md{ font-size: 28px; line-height: 32px; }

  .e-p1\:lg{ font-size: 24px; line-height: 28px; }
  .e-p2\:lg{ font-size: 22px; line-height: 26px; }
  .e-p3\:lg{ font-size: 20px; line-height: 24px; }
  .e-p4\:lg{ font-size: 18px; line-height: 22px; }
  .e-p5\:lg{ font-size: 16px; line-height: 20px; }
  .e-p6\:lg{ font-size: 14px; line-height: 18px; }

  .e-h1\:lg{ font-size: 60px; line-height: 64px; }
  .e-h2\:lg{ font-size: 54px; line-height: 58px; }
  .e-h3\:lg{ font-size: 46px; line-height: 50px; }
  .e-h4\:lg{ font-size: 38px; line-height: 42px; }
  .e-h5\:lg{ font-size: 32px; line-height: 36px; }
  .e-h6\:lg{ font-size: 28px; line-height: 32px; }
}

@media screen and (min-width: $breakpoints-xl){
  .e-h1,.e-h1\:sm,.e-h1\:md,.e-h1\:lg{ font-size: 72px; line-height: 76px; }
  .e-h2,.e-h2\:sm,.e-h2\:md,.e-h2\:lg{ font-size: 64px; line-height: 68px; }
  .e-h3,.e-h3\:sm,.e-h3\:md,.e-h3\:lg{ font-size: 58px; line-height: 62px; }
  .e-h4,.e-h4\:sm,.e-h4\:md,.e-h4\:lg{ font-size: 44px; line-height: 48px; }
  .e-h5,.e-h5\:sm,.e-h5\:md,.e-h5\:lg{ font-size: 40px; line-height: 44px; }
  .e-h6,.e-h6\:sm,.e-h6\:md,.e-h6\:lg{ font-size: 36px; line-height: 40px; }

  .e-p1,.e-p1\:sm,.e-p1\:md,.e-p1\:lg{ font-size: 26px; line-height: 28px; }
  .e-p2,.e-p2\:sm,.e-p2\:md,.e-p2\:lg{ font-size: 24px; line-height: 26px; }
  .e-p3,.e-p3\:sm,.e-p3\:md,.e-p3\:lg{ font-size: 22px; line-height: 24px; }
  .e-p4,.e-p4\:sm,.e-p4\:md,.e-p4\:lg{ font-size: 20px; line-height: 22px; }
  .e-p5,.e-p5\:sm,.e-p5\:md,.e-p5\:lg{ font-size: 18px; line-height: 20px; }
  .e-p6,.e-p6\:sm,.e-p6\:md,.e-p6\:lg{ font-size: 16px; line-height: 18px; }

  .e-h1\:xl{ font-size: 72px; line-height: 76px; }
  .e-h2\:xl{ font-size: 64px; line-height: 68px; }
  .e-h3\:xl{ font-size: 58px; line-height: 62px; }
  .e-h4\:xl{ font-size: 44px; line-height: 48px; }
  .e-h5\:xl{ font-size: 40px; line-height: 44px; }
  .e-h6\:xl{ font-size: 36px; line-height: 40px; }

  .e-p1\:xl{ font-size: 26px; line-height: 28px; }
  .e-p2\:xl{ font-size: 24px; line-height: 26px; }
  .e-p3\:xl{ font-size: 22px; line-height: 24px; }
  .e-p4\:xl{ font-size: 20px; line-height: 22px; }
  .e-p5\:xl{ font-size: 18px; line-height: 20px; }
  .e-p6\:xl{ font-size: 16px; line-height: 18px; }
}