@import '~@Styles/globals.scss';

.p_home {
    margin-top: 20px;
}

.p_home__buttons{
    & > button {
       width: 100%;
       margin: 6px 0;
       padding: 8px 32px;
    }
}

.p_home__coleccion_grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px;
    justify-content: center;

    & > div {
        flex: 100%;
        max-width: 100%;
        margin:  1.6% 0;
        height: 174px;
    }
}

@media screen and (min-width: $breakpoints-sm){
    .p_home__coleccion_grid {
        // justify-content: flex-start;
        & > div {
            max-width: 326px;
            margin:  8px;
            height: 174px;
        }
    }
}

@media screen and (min-width: $breakpoints-md){
    
    .p_home__head {
        display: flex;
        justify-content: center;
        margin-bottom: 68px;
        padding: 0 8px;
    }

    .p_home__buttons{
        display: flex;
        flex-direction: column;
    }

    .p_home__coleccion_grid {
        & > div {
            max-width: 300px;
        }
    }
}

@media screen and (min-width: $breakpoints-lg){
    .p_home__coleccion_grid {
        // justify-content: flex-start;
        & > div {
            max-width: 320px;
            height: 220px;
        }
    }
}

@media screen and (min-width: $breakpoints-xl){}