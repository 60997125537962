@import '~@Styles/globals.scss';

.c_button {
    border: none;
    padding: 16px;
    cursor: pointer;
    &.solid {
        background: black;
        color: white;
        border: 2px solid black;
    }

    &.linear {
        background: transparent;
        color: black;
        border: 2px solid black;
    }

    &.dark {
        background: transparent;
        color: white;
        border: 2px solid white;
    }

    &.black {
        background: black;
        color: white;
        border: 2px solid black;
        
    }
    
    &:disabled {
        opacity: 0.7;
    }

    &.isLoading {
        padding: 16px;
        opacity: 0.7;
    }
}

@media screen and (min-width: $breakpoints-sm){
    .c_button {
        border: none;
        padding: 10px;
    }
}

@media screen and (min-width: $breakpoints-md){
    .c_button {
        border: none;
        padding: 8px;
    }
}

@media screen and (min-width: $breakpoints-lg){}

@media screen and (min-width: $breakpoints-xl){}