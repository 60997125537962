
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

@mixin fadein(
	$duration: $fadein-duration
){
    animation: fadein $duration;
    -moz-animation: fadein $duration; /* Firefox */
    -webkit-animation: fadein $duration; /* Safari and Chrome */
    -o-animation: fadein $duration; /* Opera */
}
