@import '~@Styles/globals.scss';

.c_footer{
    background: $color-gray-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 32px 0;

}

.c_footer__button{
    width: 90%;
    margin-bottom: 16px;
}

.c_footer__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-bottom: 24px;
    & > button {
        width: 48%;
    }
}

.c_footer__logo {
    margin-top: 24px;
    fill:white;
    object-fit: contain;
}

.c_footer__partners{
    margin-top: 45px;
    color: white;
    width: 90%;
}

.c_footer__partners_list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 99%;
    > a {
        font-size: 0;
        margin: 0 8px;
    }
    > p {
        color: white;
    }
    img {
        object-fit: contain;
    }
}

.c_footer__partners_list__Colab{
    margin-top: 32px;
    a {
        margin:8px;
    }
}

.c_footer__partners_list_bg__White{
    background: white;
    padding: 8px;
    font-size: 0;
    svg{
       height: 50px; 
    }
}

.c_footer__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    > div, > a {
        text-align: center;
    }
}

.c_footer__wrapper__partners{
   flex-direction: column-reverse;
}

.c_footer__wrapper_buttons{
    width: 100%;
}

.c_footer__button {
    width: 100%;
}

.c_footer__buttons {
    width: 100%;
}

.c_footer__partners_list_image {
    max-width: 100%;
}

@media screen and (min-width: $breakpoints-sm){
    .c_footer {
        position: relative;
        padding: 16px 0;
    } 
}

@media screen and (min-width: $breakpoints-md){

    .c_footer {
        padding: 32px 0;
    }

    .c_footer__logo {
        margin-top: 0px;
        height: auto;
        width: 150px;
    }
    
    .c_footer__wrapper{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-around;
        max-width: 1600px;
        width: calc(100% - 64px);
        padding: 16px 32px;
        > div, > a {
            text-align: center;
        }
    }

    .c_footer__wrapper__partners{
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 32px;
        p {
            text-align: left;
        }
    }

    .c_footer__wrapper_buttons{
        width: 300px;
    }

    .c_footer__buttons {
        margin-top: 0px;
        margin-bottom: 24px;
        & > button {
            width: 48%;
        }
    }

    .c_footer__partners{
        width: 45%;
        margin-top: 0;
    }

    .c_footer__partners_list{
        width: auto;
        justify-content: space-between;
        a{
            text-align: center;
            width: 30%;
            text-align: left;
            margin:0px;
        }

        &__Colab a{
            text-align: center;
        }
    }
}

@media screen and (min-width: $breakpoints-lg){}

@media screen and (min-width: $breakpoints-xl){}