@import '~@Styles/globals.scss';

.p_coleccion__medio{
    color: $color-purple-acid;
    padding-left: 46px;
}

.p_coleccion__result {
    margin-top: 16px;
    text-align: center;
}