@import '~@Styles/globals.scss';

.c_coleccion__container {
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;
    cursor: pointer;
    position: relative;
    &__Blocked{
        cursor: default; 
    }
}

.c_coleccion__image {
    @include fadein(1s);
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.c_coleccion__layer {
    @include fadein(1s);
    //background: linear-gradient(to top right, transparent 0%, transparent 50%, $color-pink-opacity 50%, $color-pink-opacity 100%);
    //background: $color-pink-opacity;
    width: 100%;
    height: 100%;
    position: relative;

    &.secondary {
        //background: $color-pink-opacity;
      //  background: linear-gradient(to bottom left, transparent 0%, transparent 50%, $color-pink-opacity 50%, $color-pink-opacity 100%);
    }
}

.c_coleccion__triangle {
	//background-color: #003bde1f;
	width: 80%;
	height: 100%;
    float: left;
    shape-outside: polygon(0% 0, 100% 100%, 0 100%);
    &__Secondary{
        //background-color: #003bde1f;
        width: 250px;
        height: 5rem;
        float: right;
        shape-outside: polygon(100% 100%, 0% 0%, 100% 0%);
    }
}

.c_coleccion__title {
    color: white;
    text-align: right;
    padding: 16px;
    text-transform: uppercase;
    &.secondary {
        text-align: left;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
}

.lazy-load-image-background {
    display: inherit !important;   
}

@media screen and (min-width: $breakpoints-sm){}

@media screen and (min-width: $breakpoints-md){
    
.c_coleccion__triangle {
    &__Secondary{
        width: 300px;
        height: 4rem;
    }
}
}

@media screen and (min-width: $breakpoints-lg){}

@media screen and (min-width: $breakpoints-xl){}